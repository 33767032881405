import { useEffect, useMemo, useState } from 'react';
import './App.scss';

import image01 from './assets/01.png';
import image02 from './assets/10.png';
import image03 from './assets/11.png';
import image04 from './assets/04.png';
import image05 from './assets/08.png';
import image06 from './assets/02.png';
import image07 from './assets/05.png';
import image08 from './assets/03.png';
import image09 from './assets/06.png';
import image10 from './assets/07.png';
import image11 from './assets/09.png';
import logo from './assets/csmLogo.svg';
import arrow from './assets/arrow_drop_down_white_48dp.svg';
import misionVision from './assets/corporate-banners/b_vision_mision.jpg';
import misionVision_small from './assets/corporate-banners/b_vision_mision_small.jpg';
import juntaDirectores from './assets/corporate-banners/b_junta_de_directores.jpg';
import juntaDirectores_small from './assets/corporate-banners/b_junta_de_directores_small.jpg';
import historia from './assets/corporate-banners/b_historia.jpg';
import historia_small from './assets/corporate-banners/b_historia_small.jpg';
import boletin from './assets/corporate-banners/b_boletin_cooperando.jpg';
import boletin_small from './assets/corporate-banners/b_boletin_cooperando_small.jpg';
import programaSociales from './assets/corporate-banners/b_programas_sociales.jpg';
import programaSociales_small from './assets/corporate-banners/b_programas_sociales_small.jpg';
import centralCredit from './assets/insurance-banners/central_credit_logo.jpg';
import greenInsurance from './assets/insurance-banners/green_insurance_logo.jpg';
import multiMortgage from './assets/insurance-banners/multi_mortgage_logo.png';
import numberAutoParts from './assets/insurance-banners/number_autoparts_logo.jpg';
import InstagramLogo from './assets/instagram.png';
import FacebookLogo from './assets/facebook.svg';
import YoutubeLogo from './assets/youtube.svg';
import LinkedInLogo from './assets/linkedin.svg';

const videoRate = 1.7;
const maxSectionWidth = 960;

function App() {
	const [toggleStatus, setToggleStatus] = useState(false);
	const [toggleSection, setToggleSection] = useState('');
	const [dimensions, setDimensions] = useState({
		height: window.innerHeight,
		width: window.innerWidth,
	});
	const sectionWidth = useMemo(() => Math.min(dimensions.width, maxSectionWidth), [dimensions]);

	const toggleClick = (section: string) => {
		// Si viene undiefined
		if (!toggleSection || toggleSection === section) {
			console.log('First');
			setToggleStatus(!toggleStatus);
		} else if (toggleSection !== section) {
			console.log('Second');
			if (!toggleStatus) {
				setToggleStatus(true);
			} else {
				setToggleStatus(!toggleStatus);
			}
		}

		setToggleSection(section);
	};

	useEffect(() => {
		function handleResize() {
			setDimensions({
				height: window.innerHeight,
				width: window.innerWidth,
			});
		}
		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	console.log({ dimensions });

	return (
		<div className="junta">
			<div className="junta__div">
				<div className="junta__header">
					<img src={logo} height="30" alt="Logo" />
				</div>
				<div>
					<iframe
						title="video"
						src="https://player.vimeo.com/video/765103522?h=b0eed60c1d&color=4791ad&title=0&byline=0&portrait=0"
						width={sectionWidth}
						height={sectionWidth / videoRate}
						frameBorder="0"
						allow="autoplay; fullscreen; picture-in-picture"
						allowFullScreen
					/>
				</div>
				<div className="junta__content">
					<div className="junta__corporate-item-content">
						<div className="junta__corporate-item">
							<div className="badge" onClick={() => toggleClick('vision_mision')}>
								Visión y Misión <img src={arrow} alt="arrow" />
							</div>
							<div className="junta__corporate-item-over">
								<picture>
									<source srcSet={`${misionVision_small} 1x`} media="(max-width: 500px)" />
									<img
										className="full-width junta__corporate-img-mision-vision"
										srcSet={`${misionVision} 2x`}
										alt="Mision y Vision"
									/>
								</picture>
								<div className="junta__corporate-img-overlay"></div>
							</div>
						</div>

						{toggleStatus && toggleSection === 'vision_mision' && (
							<>
								<div className="junta__corporate-item-information">
									<h1>Visión</h1>
									<p>
										Ser el grupo asegurador más sólido prestigioso y respetado, siendo la mejor alternativa accesible
										para todos los sectores económicos de nuestro país, contribuyendo y aportando al desarrollo
										económico, social y educativo de Puerto Rico.
									</p>
									<h1>Misión</h1>
									<p>
										Ofrecer un servicio extraordinario y productos de calidad que se ajusten a las necesidades de
										nuestros clientes y socios, preservando nuestra identidad y principios cooperativos, fomentando la
										integridad ética en las actividades empresariales y comunitarias.
									</p>
									<p>
										Mantener y continuar desarrollando e implantando programas sociales y de educación cooperativa que
										abonen al desarrollo de la comunidad puertorriqueña. <br />
										<br />
										Mantener un extraordinario ambiente de trabajo, practicando y compartiendo los valores cooperativos
										como parte integral de nuestro talento humano necesario para el logro de nuestra misión.
									</p>
								</div>
							</>
						)}
					</div>
				</div>

				<div className="junta__content">
					<div className="junta__corporate-item-content">
						<div className="junta__corporate-item">
							<div className="badge" onClick={() => toggleClick('junta_directores')}>
								Junta de Directores <img src={arrow} alt="arrow" />
							</div>
							<div className="junta__corporate-item-over">
								<picture>
									<source srcSet={`${juntaDirectores_small} 1x`} media="(max-width: 500px)" />
									<img
										className="full-width junta__corporate-img-mision-vision"
										srcSet={`${juntaDirectores} 2x`}
										alt="Junsta de Directores"
									/>
								</picture>

								<div className="junta__corporate-img-overlay"></div>
							</div>
						</div>

						{toggleStatus && toggleSection === 'junta_directores' && (
							<>
								<div className="junta__content-photo">
									<div className="junta__content-photo-photos">
									<div className="junta__content-photo-photos-items">
											<div className="junta__content-photo-photos-items-box">
												<img src={image01} alt="Presidente" />
												<div className="junta__content-photo-photos-items-box-caption">
													<p>José A. Alvarado Roche</p>
													<p>Presidente</p>
												</div>
											</div>
										</div>
										<div className="junta__content-photo-photos-items">
											<div className="junta__content-photo-photos-items-box">
												<img src={image02} alt="Vicepresidente" />
												<div className="junta__content-photo-photos-items-box-caption">
													<p>Eliud Juarbe Cruz</p>
													<p>Vicepresidente</p>
												</div>
											</div>
										</div>
										<div className="junta__content-photo-photos-items">
											<div className="junta__content-photo-photos-items-box">
												<img src={image03} alt="Segunda Vicepresidenta" />
												<div className="junta__content-photo-photos-items-box-caption">
													<p>Nancy Banrey Aponte</p>
													<p>Segunda Vicepresidenta</p>
												</div>
											</div>
										</div>
										<div className="junta__content-photo-photos-items">
											<div className="junta__content-photo-photos-items-box">
												<img src={image04} alt="Secretaria" />
												<div className="junta__content-photo-photos-items-box-caption">
													<p>Yamaly Rodríguez Ventura</p>
													<p>Secretaria</p>
												</div>
											</div>
										</div>
										<div className="junta__content-photo-photos-items">
											<div className="junta__content-photo-photos-items-box">
												<img src={image05} alt="Tesorero" />
												<div className="junta__content-photo-photos-items-box-caption">
													<p>José N. Ramos Sánchez</p>
													<p>Tesorero</p>
												</div>
											</div>
										</div>
										<div className="junta__content-photo-photos-items">
											<div className="junta__content-photo-photos-items-box">
												<img src={image06} alt="Subsecretaria" />
												<div className="junta__content-photo-photos-items-box-caption">
													<p>Evelyn Alicea González</p>
													<p>Subsecretaria</p>
												</div>
											</div>
										</div>
										<div className="junta__content-photo-photos-items">
											<div className="junta__content-photo-photos-items-box">
												<img src={image07} alt="Subtesorera" />
												<div className="junta__content-photo-photos-items-box-caption">
													<p>Marilú Ortiz González</p>
													<p>Subtesorera</p>
												</div>
											</div>
										</div>
										<div className="junta__content-photo-photos-items">
											<div className="junta__content-photo-photos-items-box">
												<img src={image08} alt="Directora" />
												<div className="junta__content-photo-photos-items-box-caption">
													<p>Maritza Florán Hernández</p>
													<p>Directora</p>
												</div>
											</div>
										</div>
										<div className="junta__content-photo-photos-items">
											<div className="junta__content-photo-photos-items-box">
												<img src={image09} alt="Directora" />
												<div className="junta__content-photo-photos-items-box-caption">
													<p>Altagracia Peña Suárez</p>
													<p>Directora</p>
												</div>
											</div>
										</div>
										<div className="junta__content-photo-photos-items">
											<div className="junta__content-photo-photos-items-box">
												<img src={image10} alt="Director" />
												<div className="junta__content-photo-photos-items-box-caption">
													<p>Rolando Calderón Padilla</p>
													<p>Director</p>
												</div>
											</div>
										</div>
										<div className="junta__content-photo-photos-items">
											<div className="junta__content-photo-photos-items-box">
												<img src={image11} alt="Director" />
												<div className="junta__content-photo-photos-items-box-caption">
													<p>Emanuel Llanos Rivera</p>
													<p>Director</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</>
						)}
					</div>
				</div>

				<div className="junta__content">
					<div className="junta__corporate-item-content">
						<div className="junta__corporate-item">
							<div className="badge" onClick={() => toggleClick('historia')}>
								Historia <img src={arrow} alt="arrow" />
							</div>
							<div className="junta__corporate-item-over">
								<picture>
									<source srcSet={`${historia_small} 1x`} media="(max-width: 500px)" />
									<img
										className="full-width junta__corporate-img-mision-vision"
										srcSet={`${historia} 2x`}
										alt="Historia"
									/>
								</picture>

								<div className="junta__corporate-img-overlay"></div>
							</div>
						</div>

						{toggleStatus && toggleSection === 'historia' && (
							<>
								<div className="junta__corporate-item-information">
									<h1>Historia</h1>
									<p>
										Grupo Cooperativo Seguros Múltiples Una historia de compromiso, responsabilidad y visión de futuro
									</p>
									<p>
										El Grupo Cooperativo Seguros Múltiples (GCSM) se creó en 2016, fortaleciendo el legado de la
										organización que nació en 1963, cuando se incorporó la Cooperativa de Seguros Múltiples de Puerto
										Rico (CSM). Operando dentro del marco sólido de principios y valores cooperativos que han sido
										nuestro norte, el GCSM integra un conjunto de siete empresas de servicios relacionados a la
										industria de seguros para brindar a los clientes soluciones completas, bajo una cultura que promueve
										el servicio de excelencia.
									</p>
									<p>
										Las empresas que actualmente conforman el GCSM son la CSM, Assets Management, Central Credit, Green
										Insurance Agency, MultiMortgage, Number One Auto Parts y TeleSolutions.
									</p>
									<p>
										Cada una de estas empresas bajo la dirección del GCSM, persiguen contribuir y aportar al desarrollo
										económico, social y educativo de Puerto Rico, generando bienestar a miles de familias en nuestra
										isla a través de la calidad de los productos, los servicios y el capital humano, elevando la
										propuesta de valor del modelo cooperativista para beneficio de nuestro país.
									</p>
								</div>
							</>
						)}
					</div>
				</div>

				<div className="junta__content">
					<div className="junta__corporate-item">
						<a href="https://www.cooperandodigital.com/" target="_blank" rel="noreferrer">
							<div className="badge">Boletín Cooperando</div>
						</a>

						<div className="junta__corporate-item-over">
							<picture>
								<source srcSet={`${boletin_small} 1x`} media="(max-width: 500px)" />
								<img className="full-width junta__corporate-img-mision-vision" srcSet={`${boletin} 2x`} alt="Boletin" />
							</picture>

							<div className="junta__corporate-img-overlay"></div>
						</div>
					</div>
				</div>

				<div className="junta__content">
					<div className="junta__corporate-item">
						<a href="https://www.segurosmultiples.com/#/programassociales" target="_blank" rel="noreferrer">
							<div className="badge">Programas Sociales</div>
						</a>

						<div className="junta__corporate-item-over">
							<picture>
								<source srcSet={`${programaSociales_small} 1x`} media="(max-width: 500px)" />
								<img
									className="full-width junta__corporate-img-mision-vision"
									srcSet={`${programaSociales} 2x`}
									alt="Programa Sociales"
								/>
							</picture>
							<div className="junta__corporate-img-overlay"></div>
						</div>
					</div>
				</div>

				<div className="junta__logos">
					<ul>
						<li>
							<a href="https://www.ccc-pr.com/" target="_blank" rel="noreferrer">
								<img src={centralCredit} height="45" alt="Central Credit" />
							</a>
						</li>

						<li>
							<a href="https://www.giapr.com" target="_blank" rel="noreferrer">
								<img src={greenInsurance} height="45" alt="Green Insurance Agency" />
							</a>
						</li>
						<li>
							<a href="https://www.multi-mortgage.com/" target="_blank" rel="noreferrer">
								<img src={multiMortgage} height="45" alt="MultiMortgage" />
							</a>
						</li>

						<li>
							<a href="https://numberoneautoparts.com/" target="_blank" rel="noreferrer">
								<img src={numberAutoParts} height="45" alt="Number One Auto Parts" />
							</a>
						</li>
					</ul>
				</div>

				<div className="cont-footer">
					<div className="links-box ">
						<div>
							<div className="flex">
								<section className="res pointer outline">
									<a href="https://www.segurosmultiples.com/#/contact" target="_blank" rel="noreferrer">
										Localiza una sucursal o contáctanos
									</a>
								</section>
								<section className="res pointer outline">
									<a href="https://www.segurosmultiples.com/#/termsuse" target="_blank" rel="noreferrer">
										Términos de uso
									</a>
								</section>
								<section className="res pointer outline">
									<a href="https://www.segurosmultiples.com/#/policyprivacy" target="_blank" rel="noreferrer">
										Política de privacidad
									</a>
								</section>
								<section className="res outline">
									<a
										target="_blank"
										href="https://workforcenow.adp.com/jobs/apply/posting.html?client=coopsegmul&ccId=97137948_1890&type=MP&lang=es_US"
										className="outline"
										rel="noreferrer"
									>
										Oportunidades de empleo
									</a>
								</section>
								<section className="res pointer outline">
									<a href="https://www.segurosmultiples.com/#/fraudreport" target="_blank" rel="noreferrer">
										Reportar un fraude
									</a>
								</section>
							</div>
						</div>

						<p>
							© {new Date().getFullYear()} Derechos Reservados-Cooperativa de Seguros Múltiples de Puerto Rico * El
							contenido de este "website" está sujeto a los términos, condiciones, limitaciones y exclusiones del
							contrato de la póliza.
						</p>
					</div>

					<div className="flex social display-block">
						<a target="_blank" href="https://es-la.facebook.com/SegurosMultiplesPR" rel="noreferrer">
							<img src={FacebookLogo} alt="" height="30" />
						</a>

						<a target="_blank" href="https://www.instagram.com/segurosmultiplespr" rel="noreferrer">
							<img className="instagram-logo" src={InstagramLogo} alt="" height="30" />
						</a>

						<a target="_blank" href="https://www.youtube.com/channel/UCknt6RC-6JoCN4TUfWinf9A" rel="noreferrer">
							<img src={YoutubeLogo} alt="" height="30" />
						</a>

						<a
							target="_blank"
							href="https://www.linkedin.com/company/grupo-cooperativo-seguros-m%C3%BAltiples/about/?viewAsMember=true"
							rel="noreferrer"
						>
							<img className="linkedIn-logo" src={LinkedInLogo} alt="" height="30" />
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}

export default App;
